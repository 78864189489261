import Vue from "vue";
import VueRouter from "vue-router";
import Introduction from "../views/Introduction.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Introduction,
	},
	{
		path: "/introduction",
		name: "Introduction",
		component: Introduction,
	},
	{
		path: "/getting-started",
		name: "GettingStarted",
		component: () =>
			import(
				/* webpackChunkName: "Getting started" */ "../views/GettingStarted.vue"
			),
	},
	{
		path: "/understand-basics",
		name: "UnderstandBasics",
		component: () =>
			import(
				/* webpackChunkName: "Understand basics" */ "../views/UnderstandBasics.vue"
			),
	},
	{
		path: "/practical-case",
		name: "PracticalCase",
		component: () =>
			import(
				/* webpackChunkName: "Understand basics" */ "../views/PracticalCase.vue"
			),
	},
	{
		path: "/components-and-views",
		name: "ComponentsAndViews",
		component: () =>
			import(
				/* webpackChunkName: "Understand basics" */ "../views/ComponentsAndViews.vue"
			),
	},
	{
		path: "/routes",
		name: "Routes",
		component: () =>
			import(/* webpackChunkName: "Understand basics" */ "../views/Routes.vue"),
	},
	{
		path: "/good-practices",
		name: "GoodPractices",
		component: () =>
			import(
				/* webpackChunkName: "Understand basics" */ "../views/GoodPractices.vue"
			),
	},
	{
		path: "/working-with-scss",
		name: "Working with Scss",
		component: () =>
			import(
				/* webpackChunkName: "Understand basics" */ "../views/WorkingWithScss.vue"
			),
	},
	{
		path: "/working-with-typescript",
		name: "Working with Typescript",
		component: () =>
			import(
				/* webpackChunkName: "Understand basics" */ "../views/WorkingWithTypescript.vue"
			),
	},
	{
		path: "/production",
		name: "Production",
		component: () =>
			import(
				/* webpackChunkName: "Understand basics" */ "../views/Production.vue"
			),
	},
	{
		path: "/comparison",
		name: "Comparison",
		component: () =>
			import(
				/* webpackChunkName: "Understand basics" */ "../views/Comparison.vue"
			),
	},
	{
		path: "/whats-next",
		name: "WhatNext",
		component: () =>
			import(
				/* webpackChunkName: "Understand basics" */ "../views/WhatNext.vue"
			),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
