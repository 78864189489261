import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueHighlightJS from "vue-highlight.js";

// Highlight.js languages (Only required languages)
import css from "highlight.js/lib/languages/css";
import javascript from "highlight.js/lib/languages/javascript";
import bash from "highlight.js/lib/languages/bash";
import vue from "vue-highlight.js/lib/languages/vue";

Vue.use(VueHighlightJS, {
	// Register only languages that you want
	languages: {
		css,
		javascript,
		vue,
		bash,
	},
});

Vue.config.productionTip = false;

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
