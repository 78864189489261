<template>
	<div id="app">
		<TheSidebar />
		<div class="site-content">
			<router-view />
		</div>
	</div>
</template>

<script>
import TheSidebar from "@/components/TheSidebar";

export default {
	components: {
		TheSidebar,
	},
};
</script>

<style scoped>
#app {
	position: relative;
}

.site-content {
	max-width: 80rem;
	margin: 0 auto;
	padding: 16rem 0 16rem 13.6rem;
}
</style>
