<template>
	<aside class="TheSidebar">
		<a href="/" class="TheSidebar__logo">Vue<span class="color">able</span></a>
		<nav class="TheSidebar__nav">
			<ul class="TheSidebar__menu">
				<router-link to="/introduction" class="TheSidebar__item"
					>Introduction</router-link
				>
				<router-link to="/getting-started" class="TheSidebar__item"
					>Getting started</router-link
				>
				<router-link to="/understand-basics" class="TheSidebar__item"
					>Understand basics</router-link
				>
				<router-link to="/practical-case" class="TheSidebar__item"
					>Practical case</router-link
				>
				<router-link to="/components-and-views" class="TheSidebar__item"
					>Components and views</router-link
				>
				<router-link to="/routes" class="TheSidebar__item">Routes</router-link>
				<router-link to="/good-practices" class="TheSidebar__item"
					>Good practices</router-link
				>
				<router-link to="/working-with-scss" class="TheSidebar__item"
					>Working with SCSS</router-link
				>
				<router-link to="/working-with-typescript" class="TheSidebar__item"
					>Working with Typescript</router-link
				>
				<router-link to="/production" class="TheSidebar__item"
					>Production</router-link
				>
				<router-link to="/comparison" class="TheSidebar__item"
					>Comparison</router-link
				>
				<router-link to="/whats-next" class="TheSidebar__item"
					>What's next?</router-link
				>
			</ul>
		</nav>
	</aside>
</template>

<script>
export default {
	name: "TheSidebar",
};
</script>

<style lang="scss">
@import "../styles/components/TheSidebar.scss";
</style>
